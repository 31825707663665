* {
  box-sizing: border-box;
}

body {
  font-size: 100%;
  font-family: var(--sans-serif);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img,
svg {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  color: var(--black);
  text-decoration-line: underline;
  text-decoration-color: var(--ga-red);
  text-underline-offset: 2px;
  text-decoration-thickness: 2px;

  &:hover {
    color: var(--ga-red);
  }

  &:focus,
  &:active {
    color: var(--black);
    text-decoration-color: var(--black);
  }

  &.external-link {
    &::after {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Cdefs/%3E%3Cg fill-rule='evenodd' clip-rule='evenodd'%3E%3Cpath d='M2 2h3v1H3v6h6V7h1v3H2z'/%3E%3Cpath d='M6.211 2H10v3.789L8.579 4.368 6.447 6.5 5.5 5.553l2.132-2.132z'/%3E%3C/g%3E%3C/svg%3E");
      content: '';
      display: inline-block;
      width: 1em;
      height: 1em;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

p,
li {
  line-height: 1.35;
  max-width: 85ch;
}

:root {
  /* Fonts */
  --sans-serif: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --system-font: system-ui, -apple-system;

  /* Colours */
  --red: rgb(252, 29, 66);
  --ga-red: rgb(229, 27, 36);
  --white: rgb(248, 248, 248);
  --black: rgb(33, 33, 33);
  --shade-white: rgb(204, 204, 204);
  --shade-black: rgb(86, 86, 86);
  --inline-code-grey: rgba(27, 31, 35, 0.05);
  --code-hightlight: rgba(68, 78, 88, 0.5);
  --ga-teal: rgb(30, 202, 199);
  --ga-yellow: rgb(255, 216, 0);
  --ga-burgundy: rgb(113, 10, 51);
  --ga-pink: rgb(255, 157, 182);
  --wood: #50ba71;
  --fire: #d9413a;
  --earth: #f2b362;
  --metal: #cccec6;
  --water: #2e8493;
}
